import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import JobOrders from "./Screens/jobOrders";
import FindTalent from "./Screens/findTalent";
import Forgetpassword from "./Screens/forgetpassword";
import "react-toastify/dist/ReactToastify.css";
import Createpassword from "./Screens/createpassword";
import Changepassword from "./Screens/changepassword";
import JobDetails from "./Screens/jobDetails";
import SearchCandidate from "./Screens/searchCandidate";
import Login from "./Screens/login";
import Cookies from "js-cookie";
import Createjob from "./Screens/Createjob";
import useAxiosInterceptor from "./interceptors/axios";
import ManageCandidate from "./Screens/ManageCandidate";
import OpenDrawer from "./components/OpenDrawer";
import CompanyLists from "./Screens/companyLists";
import AddCompany from "./Screens/addCompany";
import CompanyDetails from "./Screens/companyDetails";
import AddBranch from "./components/addBranch";
import BranchDetails from "./Screens/branchDetails";
import UsersLists from "./Screens/usersLists";
import UserDetails from "./Screens/userDetails";
import UserAdditon from "./Screens/userAdditon";
import UserDetailsPreview from "./Screens/userDetailsPreview";
import Customdashboard from "./Screens/Dashboard/dashboard";
import Header from "./components/header";
import { Box, Tooltip } from "@mui/material";
//
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MyDashboard from "./Screens/myDashboard/MyDashboard";
import {
  getRefreshToken,
  getTokenID,
  isEmployerLogin,
  permissionStatus,
  role,
} from "./redux/modules/userSlice";
import RichTextEditor from "./Screens/Blogs/RichTextEditor";
import BlogList from "./Screens/Blogs/BlogList";
import BlogDisplay from "./Screens/Blogs/BlogDisplay";

// import smLogo from ""

const drawerWidth = 257;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(12.75)} + 1px)`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const mailtoken = window.location.pathname?.split("/")[2]?.split("=")[1];
  const containerRef = React.useRef(null);
  const location = useLocation();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({});
  const roles = useSelector((state) => state.user.role);
  const managePermissions = useSelector((state) => state?.user.permissions);
  const token = useSelector((state) => state.user.token);
  const createPage = (label, icon, path, activePaths) => ({
    label,
    icon,
    path,
    active: activePaths.includes(location.pathname.split("/")[1]),
  });

  const employerPages = [
    createPage("Dashboard", <EqualizerOutlinedIcon />, "/mydashboard", [
      "mydashboard",
      "dashboard",
    ]),
    createPage("Jobs", <WorkOutlineOutlinedIcon />, "/jobOrders?page=1", [
      "jobOrders",
      "Postjob",
    ]),
    createPage("Companies", <BusinessOutlinedIcon />, "/Company?page=1", [
      "Company",
      "CompanyDetails",
      "addCompany",
    ]),
    createPage("Users", <PersonOutlinedIcon />, "/Users?page=1", [
      "Users",
      "UsersDetails",
      "addUser",
    ]),
    createPage("Find Talent", <PersonSearchOutlinedIcon />, "/Find-talents", [
      "Find-talents",
      "searchCandidate",
    ]),
    createPage(
      "Manage Candidates",
      <ManageAccountsOutlinedIcon />,
      "/Manage-Candidates?page=1",
      ["Manage-Candidates"]
    ),
  ];
  const recruiterPages = [
    createPage("Dashboard", <EqualizerOutlinedIcon />, "/mydashboard", [
      "mydashboard",
      "dashboard",
    ]),
    createPage("Jobs", <WorkOutlineOutlinedIcon />, "/jobOrders?page=1", [
      "jobOrders",
      "Postjob",
    ]),
    createPage("Companies", <BusinessOutlinedIcon />, "/Company?page=1", [
      "Company",
      "CompanyDetails",
      "addCompany",
    ]),

    createPage("Find Talent", <PersonSearchOutlinedIcon />, "/Find-talents", [
      "Find-talents",
      "searchCandidate",
    ]),
    createPage(
      "Manage Candidates",
      <ManageAccountsOutlinedIcon />,
      "/Manage-Candidates?page=1",
      ["Manage-Candidates"]
    ),
  ];

  const onlyBlogPages = [
    createPage("Blogs", <RssFeedIcon />, "/blogs", ["blogs", "blog"]),
  ];
  const roleBasedpermission = () => {
    if (roles === "RECRUITER") {
      return recruiterPages;
    } else if (roles === "SEO") {
      return onlyBlogPages;
    } else {
      return employerPages;
    }
  };

  const handleNavigation = (path) => {
    navigation(path);
    sessionStorage.clear();
  };
  const handleDrawerClose = () => {
    setOpen((prev) => !prev);
  };

  const excludedPaths = [
    "/login",
    "/forgetpassword",
    `/createpassword/_secure_ARJ_=${mailtoken}`,
  ];

  const shouldRenderHeader = !excludedPaths.includes(location.pathname);
  const dispatch = useDispatch();

  useAxiosInterceptor();

  useEffect(() => {
    setIsLoggedIn(
      !!(Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"))
    );
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
    console.clear();
    console.error = () => {}; // Override to do nothing
    console.warn = () => {}; // Override to do nothing
    console.log = () => {}; // Override to do nothing
    console.info = () => {}; // Override to do nothing
  }, [location.pathname, searchParams, location.search]);

  // useEffect(() => {
  //   try {
  //     if (token && (!roles || !managePermissions["600"])) {
  //       localStorage.clear();
  //       dispatch(getTokenID(null));
  //       dispatch(getRefreshToken(null));
  //       dispatch(permissionStatus(null));
  //       dispatch(isEmployerLogin(false));
  //       dispatch(role(null));
  //       const d = new Date();
  //       let expires = "expires=" + d.toUTCString();
  //       document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
  //       document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
  //       document.cookie =
  //         "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
  //       sessionStorage.clear();
  //       window.location.href = "/login";
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);

  const isAuthenticated = !!(
    Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")
  );
  return (
    <Box sx={{ display: "flex", width: "100vw" }}>
      {shouldRenderHeader && (
        <Drawer
          variant="permanent"
          open={open}
          className="drawer"
          sx={{
            "& .MuiDrawer-paper": {
              border: "none",
            },
          }}
        >
          <DrawerHeader
            sx={{ py: 3.75, height: "fit-content", minHeight: "0 !important" }}
          >
            <IconButton
              sx={{ margin: "0 auto", p: 0 }}
              onClick={handleDrawerClose}
            >
              <div className={`logo ${open ? "" : "hidden"}`}>
                <img
                  src="https://jobeze.com/Logo.svg"
                  style={{ height: "23px" }}
                  alt="Logo"
                />
              </div>
              <div className={`logo ${open ? "hidden" : ""}`}>
                <img
                  src="../smLogoVector.png"
                  style={{ height: "23px" }}
                  alt="Logo"
                />
              </div>
            </IconButton>
          </DrawerHeader>
          <List sx={{ px: 2.5, pt: 5.25 }}>
            {roleBasedpermission().map((menuItem) => (
              <ListItem
                key={menuItem.label}
                disablePadding
                sx={{ display: "block", mb: 3 }}
              >
                {open ? (
                  <ListItemButton
                    sx={{
                      px: 2.5,
                      py: 2,
                      borderRadius: "8px",
                      fontWeight: 500,
                      justifyContent: open ? "initial" : "center",
                      color: `${menuItem.active ? "#fff" : "#737791"}`,
                    }}
                    onClick={() => handleNavigation(menuItem.path)}
                    className={`${menuItem.active ? "activeSidebarMenu" : ""}`}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                        color: `${menuItem.active ? "#fff" : "#737791"}`,
                      }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.label}
                      sx={{
                        m: 0,
                        opacity: open ? 1 : 0,
                        display: open ? "block" : "none",
                      }}
                    />
                  </ListItemButton>
                ) : (
                  <Tooltip title={menuItem.label} placement="top">
                    <ListItemButton
                      sx={{
                        px: 2.5,
                        py: 2,
                        borderRadius: "8px",
                        fontWeight: 500,
                        justifyContent: open ? "initial" : "center",
                        color: `${menuItem.active ? "#fff" : "#737791"}`,
                      }}
                      onClick={() => handleNavigation(menuItem.path)}
                      className={`${
                        menuItem.active ? "activeSidebarMenu" : ""
                      }`}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                          color: `${menuItem.active ? "#fff" : "#737791"}`,
                        }}
                      >
                        {menuItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={menuItem.label}
                        sx={{
                          m: 0,
                          opacity: open ? 1 : 0,
                          display: open ? "block" : "none",
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>
                )}
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
      <Box sx={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {shouldRenderHeader && <Header />}
        <div
          ref={containerRef}
          style={{
            height: `${shouldRenderHeader ? "calc(100% - 90px)" : "100%"}`,
            width: "100%",
            overflowY: "auto",
            background: "#fafafa",
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  roles === "SEO" ? (
                    <Navigate to="/blogs" />
                  ) : (
                    <Navigate to="/mydashboard" />
                  )
                ) : (
                  <Login setIsLoggedIn={setIsLoggedIn} />
                )
              }
            />

            <Route
              path="/forgetpassword"
              element={<Forgetpassword setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route
              path={`/createpassword/_secure_ARJ_=${mailtoken}`}
              element={<Createpassword />}
            />
            <Route element={<PrivateRoutes />}>
              {roles === "SEO" && (
                <>
                  <Route path="/blogs" element={<BlogList />} />
                  <Route path="/blog/create" element={<RichTextEditor />} />
                  <Route path="/blog/details/:id" element={<BlogDisplay />} />
                  <Route path="/blog/:id/edit" element={<RichTextEditor />} />
                </>
              )}

              {roles !== "SEO" && (
                <>
                  <Route path="/changepassword" element={<Changepassword />} />
                  <Route path="/jobOrders" element={<JobOrders />} />
                  <Route path="/Postjob" element={<Createjob />} />
                  <Route path="/Find-talents" element={<FindTalent />} />
                  <Route
                    path="/Manage-Candidates"
                    element={<ManageCandidate />}
                  />
                  <Route
                    path="/Manage-Candidates/:id"
                    element={<OpenDrawer />}
                  />
                  <Route path="/jobOrders/:id" element={<JobDetails />} />
                  <Route
                    path="/searchCandidate"
                    element={<SearchCandidate />}
                  />
                  <Route path="/Company" element={<CompanyLists />} />
                  <Route path="/addCompany" element={<AddCompany />} />
                  <Route
                    path="/CompanyDetails/:id/addBranch"
                    element={<AddBranch />}
                  />
                  <Route
                    path="/CompanyDetails/:id"
                    element={<CompanyDetails />}
                  />
                  <Route
                    path="/CompanyDetails/:id/BranchDetails/:branchId"
                    element={<BranchDetails />}
                  />
                </>
              )}
              {roles !== "RECRUITER" && roles !== "SEO" && (
                <>
                  <Route path="/Users" element={<UsersLists />} />
                  <Route path="/UsersDetails/:id" element={<UserDetails />} />
                  <Route path="/addUser" element={<UserAdditon />} />
                </>
              )}
              {roles !== "SEO" && (
                <>
                  <Route path="/dashboard" element={<Customdashboard />} />
                  <Route path="/mydashboard" element={<MyDashboard />} />
                </>
              )}
              {roles === "SEO" ? (
                <Route path="*" element={<Navigate to="/blogs" />} />
              ) : (
                <Route path="*" element={<Navigate to="/mydashboard" />} />
              )}
            </Route>
          </Routes>
        </div>
      </Box>
    </Box>
  );
}

function PrivateRoutes() {
  const isAuthenticated = !!(
    Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")
  );
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;

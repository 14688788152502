import React, { useEffect, useState } from "react";
import Header from "../components/header";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  px: 7,
  textAlign: "center",
};

const editorConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "insertTable",
      "undo",
      "redo",
    ],
  },
  placeholder: "Write the Description here...",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddCompany = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  // console.log(searchParams.get("edit"));

  const [formState, setFormState] = useState({
    name: "",
    url: "",
    site_id: 1,
    phone: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    zip: "",
    notes: "",
    is_branch_managed: 0,
  });

  const [ManageBranchPopup, setManageBranchPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [zipCodes, setZipCodes] = useState([]);
  const [selectedZipCode, setSelectedZipCode] = useState(0);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [errors, setErrors] = useState({});
  const company_id = searchParams.get("c_id");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));

    if (name === "website") {
      const websiteRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*\/?$/;
      // const websiteRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
      if (!websiteRegex.test(value)) {
        setErrors((prev) => ({ ...prev, website: "Invalid website URL" }));
      } else {
        setErrors((prev) => {
          const { website, ...rest } = prev;
          return rest;
        });
      }
    }

    // if(name === "phone"){
    //   // check that phone cannot be lessthan 10 number
    //   if(value.length < 10){
    //     setErrors((prev) => ({ ...prev, phone: "It should contain exactly 10 digits." }));
    //   }else{
    //     setErrors((prev) => {
    //       const { phone, ...rest } = prev;
    //       return rest;
    //     });
    //   }
    // }

    if (name === "is_branch_managed") {
      if (value === "1") {
        setManageBranchPopup(true);
      }
      setFormState((prev) => ({ ...prev, is_branch_managed: Number(value) }));
    }
  };

  // const handleLogoChange = (e) => {
  //   const file = e.target.files[0];
  //   const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  //   const maxSizeInBytes = 5 * 1024 * 1024;
  //   console.log("file", file);
  //   if (file && validImageTypes.includes(file.type)) {
  //     if (!validImageTypes.includes(file.type)) {
  //       // Show error message for invalid file type
  //       setSnackbarSeverity('error');
  //       setSnackbarMessage('Please select a valid image file (JPEG, PNG, JPG).');
  //       setSnackbarOpen(true);
  //       return;
  //     }

  //     // Check file size
  //     if (file.size > maxSizeInBytes) {
  //       // Show error message for file size
  //       setSnackbarSeverity('error');
  //       setSnackbarMessage('File size should not exceed 5 MB.');
  //       setSnackbarOpen(true);
  //       return;
  //     }
  //   }else if(file){
  //   setFormState((prev) => ({ ...prev, logo: file }));
  //   setFileName(file?.name);
  //   setSnackbarSeverity('success');
  //   setSnackbarMessage('File selected successfully.');
  //   setSnackbarOpen(true);
  //   }
  // };
  const [logoPreview, setLogoPreview] = useState(null);
  const handleLogoChange = (e) => {
    console.log("file", e.target);
    const file = e.target.files[0];
    const reader = new FileReader();

    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (file && !validImageTypes.includes(file.type)) {
      // Show error message or set error state
      setSnackbarSeverity("error");
      setSnackbarMessage("Please select a valid image file (JPEG, PNG, JPG).");
      setSnackbarOpen(true);
      return;
    } else if (file?.size > maxSizeInBytes) {
      // Show error message or set error state
      setSnackbarSeverity("error");
      setSnackbarMessage("File size should not exceed 5 MB.");
      setSnackbarOpen(true);
      return;
    } else if (file) {
      setFormState((prev) => ({ ...prev, logo: file }));
      setFileName(file?.name);
      setSnackbarSeverity("success");
      setSnackbarMessage("File selected successfully.");
      setSnackbarOpen(true);
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setErrors((prev) => {
        const { logo, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleStateChange = (event) => {
    const selectedIsoCode = event.target.value;
    setSelectedState(selectedIsoCode);
    setFormState({ ...formState, state: selectedIsoCode });
    fetchCities(selectedIsoCode);
    if (searchParams.get("edit") === "1") {
      setFormState((prev) => ({ ...prev, city: "", zip: "" }));
      // setFormState({ ...formState, city: "" });
      // setFormState({ ...formState, zip : "" });
      setSelectedCity("");
      setSelectedZipCode("");
    }

    // console.log(selectedIsoCode, "isoCode of selected state");
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setSelectedCity(selectedCity);
    setSelectedZipCode("");
    setFormState({ ...formState, city: selectedCity });
    fetchZipCodes(selectedCity);
    // console.log(selectedCity, "Selected city");
  };

  const handleZipCodeChange = (event) => {
    const selectedZipCode = event.target.value;
    setSelectedZipCode(selectedZipCode);
    setFormState({ ...formState, zip: String(selectedZipCode) });
    // console.log(selectedZipCode, "Selected zip code");
  };

  // const handleOpenManageBranchPopup = () => setOpenManageBrancePopup(true);
  const handleManageBranchPopup = () => {
    setFormState((prev) => ({ ...prev, is_branch_managed: 0 }));
    setManageBranchPopup(false);
  };

  // original_date_modified

  // Function to fetch states
  const fetchStates = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JOBEZEURL + "/state-city/list",
        {}
      );
      // console.log("states response", response);
      setStates(response?.data?.message || []);
    } catch (error) {
      console.error("Error fetching state-city data", error);
    }
  };
  // Function to fetch cities based on state isoCode
  const fetchCities = async (stateName) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JOBEZEURL + "/state-city/list",
        { stateName: stateName }
      );
      setCities(response?.data?.message || []);
      // console.log(selectedCity, "Selected city");
      // setSelectedCity(selectedCity);
    } catch (error) {
      console.error("Error fetching cities data", error);
    }
  };

  const fetchZipCodes = async (city) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JOBEZEURL + "/state-city/list",
        { city: city }
      );
      setZipCodes(response?.data?.message || []);
    } catch (error) {
      console.error("Error fetching cities data", error);
    }
  };

  // original_date_modified

  const [logoUrl, setLogoUrl] = useState("");

  const getCompanyDetails = async () => {
    setLoading(true);
    const response = await axios.get(`/company-info/${company_id}`, {
      headers: {
        authorization:
          Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
      },
    });
    const companyData = response.data.message[0];
    setLogoUrl(companyData.logo);
    const { logo, ...rest } = companyData;
    setFormState((prev) => ({ ...prev, ...rest }));

    // setFormState(prev=>({...prev, ...companyData}));
    setFileName(companyData.logo_name);
    setSelectedState(companyData.state);
    setSelectedCity(companyData.city);
    fetchCities(companyData.state);
    setSelectedZipCode(Number(companyData.zip));
    fetchZipCodes(companyData.city);
    setLoading(false);
    // setCompanyInfo(response.data.message[0]);
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    fetchStates();
    if (searchParams.get("edit") === "1") {
      getCompanyDetails();
    }
  }, []);

  const validateForm = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const newErrors = {};
    const websiteRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*\/?$/;
    // const websiteRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    if (!formState.name) newErrors.name = "Company Name is required";
    if (!formState.phone) newErrors.phone = "Contact Number is required";
    if (!formState.addressLine1)
      newErrors.addressLine1 = "Address Line 1 is required";
    if (!formState.state) newErrors.state = "State is required";
    if (!formState.city) newErrors.city = "City is required";
    // if (!formState.zip) newErrors.zip = "Postal Code is required";
    if (formState.url && !websiteRegex.test(formState.url))
      newErrors.url = "Invalid website URL";
    if (formState.phone.length !== 10) {
      newErrors.phone = "It should contain exactly 10 digits.";
    }
    if (!(searchParams.get("edit") === "1")) {
      if (!formState.logo) {
        newErrors.logo = "Logo is required";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const editCompany = async () => {
    try {
      const formData = new FormData();
      // console.log(formState, "edit form state");
      formData.append("name", formState.name);
      formData.append("url", formState.url);
      formData.append("phone", formState.phone);
      formData.append("addressLine1", formState.addressLine1);
      formData.append("addressLine2", formState.addressLine2);
      formData.append("state", formState.state);
      formData.append("city", formState.city);
      formData.append("zip", formState.zip);
      formData.append("notes", formState.notes);
      formData.append("is_branch_managed", formState.is_branch_managed);
      formData.append(
        "original_date_modified",
        formState.original_date_modified
      );
      if (formState?.logo) {
        formData.append("logo", formState.logo);
      }

      const response = await axios.post(
        `/company/edit/${company_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      setSnackbarMessage(response.data?.message);
      return response;
    } catch (error) {
      console.error("Error creating company", error);
      setSnackbarMessage(error?.response?.data?.error);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const createCompany = async () => {
    try {
      const formData = new FormData();
      // console.log(formState, "form state");
      formData.append("name", formState.name);
      formData.append("url", formState.url);
      formData.append("phone", formState.phone);
      formData.append("addressLine1", formState.addressLine1);
      formData.append("addressLine2", formState.addressLine2);
      formData.append("state", formState.state);
      formData.append("city", formState.city);
      formData.append("zip", formState.zip);
      formData.append("notes", formState.notes);
      formData.append("is_branch_managed", formState.is_branch_managed);
      if (formState?.logo) {
        formData.append("logo", formState.logo);
      }

      const response = await axios.post("/company/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setSnackbarMessage(response.data?.message?.msg);
      return response;
    } catch (error) {
      console.error("Error creating company", error);
      setSnackbarMessage(error?.response?.data?.error);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleResponse = (response) => {
    if (response) {
      setSnackbarSeverity(response.data?.success ? "success" : "error");
      setTimeout(() => {
        setSnackbarOpen(true);
      }, 1500);
      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 2000);
    } else {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === false) {
    } else {
      setLoading(true);
      if (searchParams.get("edit") === "1") {
        const edit_response = await editCompany();
        handleResponse(edit_response);
      } else {
        const create_response = await createCompany();
        handleResponse(create_response);
      }
    }
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          backgroundColor: "rgba(242, 248, 255, 1)",
        }}
        className="pageWrapper"
      >
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box item sx={{ width: "100%" }}>
            <Box
              flex={1}
              component={"div"}
              sx={{
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                borderRadius: 5,
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            >
              <Box>
                <Box>
                  <Typography
                    sx={{ p: 2, px: 3 }}
                    fontWeight={500}
                    fontSize={"22px"}
                    variant="body1"
                  >
                    {searchParams.get("edit") === "1"
                      ? "Edit Company Details"
                      : "Add Company"}
                  </Typography>
                  <Divider />
                </Box>
                <Box
                  sx={{ p: 2, px: 3 }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                >
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        sx={{
                          borderRadius: "6px",
                          px: 4,
                          py: 1.9,
                          textTransform: "none",
                          borderColor: errors.logo ? "red" : "",
                          color: errors.logo ? "red" : "",
                        }}
                        tabIndex={-1}
                        startIcon={<FileUploadIcon />}
                      >
                        Upload your logo
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/*"
                          onChange={handleLogoChange}
                        />
                      </Button>
                      {fileName && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                          pt={2}
                        >
                          <Typography variant="body2" fontWeight={500}>
                            Uploaded Logo:
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={400}
                            px={1}
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: "50%",
                            }}
                          >
                            {fileName}
                          </Typography>
                        </Box>
                      )}
                      {errors.logo && (
                        <Typography
                          variant="body2"
                          fontSize={12}
                          px={2}
                          pt={1}
                          color={"red"}
                        >
                          {errors.logo}
                        </Typography>
                      )}
                    </Box>
                    <Box px={3}>
                      {logoPreview ? (
                        <img
                          alt="logo"
                          src={logoPreview}
                          style={{
                            width: "130px",
                            height: "120px",
                            borderRadius: 4,
                          }}
                        />
                      ) : (
                        logoUrl && (
                          <img
                            alt="logo"
                            src={logoUrl}
                            style={{
                              width: "130px",
                              height: "120px",
                              borderRadius: 4,
                            }}
                          />
                        )
                      )}
                    </Box>
                  </Box>
                  <Typography>
                    <TextField
                      name="name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Company Name"
                      inputProps={{ maxLength: 60 }}
                      required
                      value={formState.name}
                      onChange={handleInputChange}
                      onFocus={() => setErrors({ ...errors, name: "" })}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Typography>
                  <Typography display={"flex"} alignItems={"center"} gap={4}>
                    <Typography>
                      Do you want to manage branches of this company?
                    </Typography>
                    <Typography>
                      <FormControl
                        disabled={formState.is_branch_managed === 1}
                        fullWidth
                      >
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="is_branch_managed"
                          value={formState.is_branch_managed}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            label="Yes"
                            value={1}
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 28,
                                  },
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            label="No"
                            value={0}
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 28,
                                  },
                                }}
                              />
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Typography>
                  </Typography>
                  <Typography>
                    <TextField
                      name="url"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Website"
                      // inputProps={{ maxLength: 120 }}
                      value={formState.url}
                      onChange={handleInputChange}
                      onFocus={() => setErrors({ ...errors, url: "" })}
                      error={!!errors.url}
                      helperText={errors.url}
                    />
                  </Typography>
                  <Typography>
                    <TextField
                      name="phone"
                      variant="outlined"
                      fullWidth
                      type="tel"
                      size="small"
                      placeholder="Contact Number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+ 1</InputAdornment>
                        ),
                        inputProps: {
                          maxLength: 10,
                          onKeyPress: (e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (
                              charCode > 31 &&
                              (charCode < 48 || charCode > 57)
                            ) {
                              e.preventDefault();
                            }
                          },
                          pattern: "[0-9]*",
                        },
                      }}
                      value={formState.phone}
                      onChange={handleInputChange}
                      onPaste={(e) => e.preventDefault()}
                      onFocus={() => setErrors({ ...errors, phone: "" })}
                      error={!!errors.phone}
                      helperText={errors.phone}
                    />
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ px: 3 }}>
                <Box>
                  <Typography
                    fontWeight={500}
                    py={1.5}
                    variant="body1"
                    gutterBottom
                  >
                    Address
                  </Typography>
                </Box>
                <Modal
                  open={ManageBranchPopup}
                  // onClose={handleCloseManageBrancePopup}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      fontWeight={500}
                      component="h2"
                    >
                      Do you want to manage branches for this Company?
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      variant="body2"
                      fontWeight={400}
                      sx={{ mt: 2, color: "#ed6c02" }}
                    >
                      Once manage branch feature is enabled, It can't be
                      disabled!
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      variant="body2"
                      display={"flex"}
                      justifyContent={"space-evenly"}
                      alignItems={"center"}
                      sx={{ mt: 4 }}
                    >
                      <Button
                        variant="outlined"
                        sx={{ width: "40%", textTransform: "none" }}
                        onClick={handleManageBranchPopup}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          width: "40%",
                          textTransform: "none",
                          letterSpacing: "1.5px",
                        }}
                        onClick={() => setManageBranchPopup(false)}
                      >
                        OK
                      </Button>
                    </Typography>
                  </Box>
                </Modal>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography>
                    <TextField
                      name="addressLine1"
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                      placeholder="Address Line 1"
                      value={formState.addressLine1}
                      onChange={handleInputChange}
                      onFocus={() => setErrors({ ...errors, addressLine1: "" })}
                      error={!!errors.addressLine1}
                      helperText={errors.addressLine1}
                    />
                  </Typography>
                  <Typography>
                    <TextField
                      name="addressLine2"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Address Line 2"
                      value={formState.addressLine2}
                      onChange={handleInputChange}
                      // error={!!errors.addressLine2}
                      // helperText={errors.addressLine2}
                    />
                  </Typography>
                </Box>
                <Box
                  sx={{ pt: 2 }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <FormControl
                    fullWidth
                    sx={{ position: "relative" }}
                    error={!!errors.state}
                  >
                    <InputLabel size="small" id="demo-simple-select-label">
                      State
                    </InputLabel>
                    <Select
                      labelId="state-select-label"
                      id="state-select"
                      value={selectedState}
                      size="small"
                      label="State"
                      onChange={handleStateChange}
                      onFocus={() => setErrors({ ...errors, state: "" })}
                    >
                      {states?.map((state) => (
                        <MenuItem key={state.name} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.state && (
                      <Typography fontSize={12} px={1.5} py={0.5} color="error">
                        {errors.state}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth error={!!errors.city}>
                    <InputLabel size="small" id="demo-simple-select-label">
                      City
                    </InputLabel>
                    <Select
                      labelId="city-select-label"
                      id="city-select"
                      value={selectedCity}
                      size="small"
                      label="City"
                      onChange={handleCityChange}
                      onFocus={() => setErrors({ ...errors, city: "" })}
                      disabled={!selectedState}
                    >
                      {cities?.map((city) => (
                        <MenuItem key={city.city} value={city.city}>
                          {city.city}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.city && (
                      <Typography fontSize={12} px={1.5} py={0.5} color="error">
                        {errors.city}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Zip Code
                    </InputLabel>
                    <Select
                      labelId="zip-select-label"
                      id="zip-select"
                      value={selectedZipCode || ""}
                      size="small"
                      label="Zip Code"
                      onChange={handleZipCodeChange}
                      // onFocus={() => setErrors({ ...errors, city: "" })}
                      disabled={!selectedCity}
                    >
                      {zipCodes?.map((zip) => (
                        <MenuItem key={zip.zip} value={zip.zip}>
                          {zip.zip}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ px: 3, py: 1.5 }}>
                <Box>
                  <Typography
                    fontWeight={500}
                    py={1.5}
                    variant="body1"
                    gutterBottom
                  >
                    Additional Info
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfig}
                      data={formState.notes}
                      // onChange={handleEditorChange}
                      onChange={(event, editor) => {
                        setFormState((prev) => ({
                          ...prev,
                          notes: editor.getData(),
                        }));
                        // console.log(editor.getData(), "editor data");
                        // console.log(event, "event");
                      }}
                      onReady={(editor) => {
                        // console.log("editor.", editor);
                      }}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ py: 2 }}>
                <Divider />
              </Box>
              <Box
                sx={{ px: 3, pb: 2 }}
                display={"flex"}
                gap={2}
                justifyContent={"end"}
              >
                <Button variant="text" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  variant="contained"
                >
                  {searchParams.get("edit") === "1" ? "Save" : "Add"}
                </Button>
              </Box>
            </Box>
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
              // variant="filled"
              elevation={6}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Grid
            item
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            gap={"20px"}
            width={{ xs: "100%", sm: "100%", md: "55%", lg: "55%" }}
            sx={{
              borderRadius: 5,
              height: "100%",
              position: "sticky",
              top: "5%",
              right: 0,
              mb: { xs: 2, sm: 3, md: 4, lg: 8 },
            }}
          >
            <Box
              sx={{
                py: 3,
                px: 3,
                backgroundColor: "#FFF3E0",
                borderRadius: "16px",
              }}
            >
              <Typography fontSize={"34px"} align="left">
                <img src="./brake-warning.png" />
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                fontSize={"20px"}
              >
                Importance
              </Typography>
              <Typography variant="body1" sx={{ pt: 2.5 }}>
                Companies are the entities posting job openings. Creating a
                separate profile for each company allows for better organization
                and representation of employers on Jobeze platform.
              </Typography>
            </Box>
            <Box
              sx={{
                py: 3,
                px: 3,
                backgroundColor: "#FFF3E0",
                borderRadius: "16px",
              }}
            >
              <Typography fontSize={"34px"} align="left">
                💡
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                fontSize={"20px"}
                sx={{ pt: 1 }}
              >
                Tips
              </Typography>
              <Typography variant="body1" sx={{ pl: 2, pt: 2.5 }}>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <li>
                    Include fields for company name, location, website, and a
                    brief description.
                  </li>
                  <li>
                    Offer options for companies to upload their logos and
                    branding materials to customize their profiles.
                  </li>
                  <li>
                    Allow companies to manage multiple job and users listings
                    under their profile.
                  </li>
                </ul>
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AddCompany;
